import { Center, Spinner } from "@chakra-ui/react";

export const Loader = () => (
  <Center width="100%" mt="24">
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      size="xl"
    />
  </Center>
);
