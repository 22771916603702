const API_BASE =
  process.env.REACT_APP_API_BASE || "http://localhost:3000/api/v1";

export const externalLoginLink = () =>
  `${API_BASE}/external/accounts/login_link`;
export const products = () => `${API_BASE}/products`;

export const plans = () => `${API_BASE}/plans`;
export const plansBySupplier = (supplierId: string) =>
  `${API_BASE}/suppliers/${supplierId}/plans`;

export const plan = (planId: string) => `${API_BASE}/plans/${planId}`;
export const planBySupplier = (supplierId: string, planId: string) =>
  `${API_BASE}/suppliers/${supplierId}/plans/${planId}`;

export const subscriptions = () => `${API_BASE}/subscriptions`;
export const overview = () => `${API_BASE}/suppliers/overview`;

export const subscription = (subscriptionId: string) =>
  `${API_BASE}/subscriptions/${subscriptionId}`;

export const consumeSubscription = (subscriptionId: string) =>
  `${API_BASE}/subscriptions/${subscriptionId}/consume`;

export const createSubscription = () => `${API_BASE}/subscriptions`;

export const login = () => `${API_BASE}/users/login`;
export const logout = () => `${API_BASE}/users/logout`;
export const currentUser = () => `${API_BASE}/users/me`;

export const supplierInfo = (id: string) => `${API_BASE}/suppliers/${id}`;
