import { useLocation, useNavigate } from "react-router-dom";
import { useSWRConfig } from "swr";
import { currentUser, login } from "../api";

export const useFetcher = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { cache } = useSWRConfig();

  return (resource: RequestInfo, init?: RequestInit): Promise<any> =>
    fetch(resource, { ...init, credentials: "include" })
      .then((res) => {
        if (!res.ok) {
          const error: Error & { info?: string; status?: number } = new Error(
            "An error occurred while fetching the data."
          );

          error.status = res.status;
          throw error;
        }

        return res;
      })
      .then(async (res) => {
        let json;
        try {
          json = await res.json();
        } catch (err) {}

        return { ...json, meta: res };
      })
      .catch((error) => {
        if (error.status === 401) {
          cache.delete(currentUser());
          navigate("/supplier/login", {
            state: { prevPathname: location.pathname },
          });

          if (resource === login()) {
            throw error;
          }
        }

        throw error;
      });
};
