import { ChakraProvider, theme } from "@chakra-ui/react";
import React from "react";

import { SWRConfig } from "swr";
import { useFetcher } from "./utils/fetcher";

export const Providers = ({ children }: { children: React.ReactElement }) => {
  const fetcher = useFetcher();

  return (
    <ChakraProvider theme={theme}>
      <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
    </ChakraProvider>
  );
};
