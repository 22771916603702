import React, { Suspense } from "react";
import { Providers } from "./Providers";
import { Routes, Route } from "react-router-dom";
import { Loader } from "./patterns/Loader";
import "./App.css";

const Subscribe = React.lazy(() => import("./features/Subscribe"));
const Offer = React.lazy(() => import("./features/Offer"));
const Admin = React.lazy(() => import("./Admin"));

export const App = () => {
  const isRoot = window.location.pathname === "/";
  if (isRoot) {
    window.location.replace("/supplier");
  }

  return (
    <Providers>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="supplier/*" element={<Admin />} />
          <Route path="offer/:supplierId" element={<Offer />} />
          <Route
            path="offer/:supplierId/subscribe/:planId"
            element={<Subscribe />}
          />
        </Routes>
      </Suspense>
    </Providers>
  );
};
